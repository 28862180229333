export interface Mosaic {
    animation?:          Animation;
    backgroundColor?:    string;
    borderRadius?:       string;
    carousel?:           boolean;
    centeredMosaics?:    boolean;
    content?:            TranslationSchema[];
    detailFullScreen?:   boolean;
    folderIds?:          number[];
    id:                  string;
    inheritedContainer?: boolean;
    labelCustom?:        LabelCustom;
    mosaicElements?:     MosaicElement[];
    mosaicMode?:         string;
    showSubTitle?:       boolean;
    size:                Size;
    title?:              string;
}

export interface Animation {
    animationType?: AnimationType;
}

export enum AnimationType {
    NoAnimation = "NO_ANIMATION",
    ZoomOnHover = "ZOOM_ON_HOVER",
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    Hu = "hu",
    It = "it",
    Nl = "nl",
    Pl = "pl",
    Pt = "pt",
    Ro = "ro",
}

export interface LabelCustom {
    backgroundTextColor?:  string;
    backgroundTextEffect?: BackgroundTextEffect;
    horizontalTextAlign?:  HorizontalTextAlign;
    textColor?:            string;
    textSize?:             string;
    verticalTextAlign?:    VerticalTextAlign;
}

export enum BackgroundTextEffect {
    BottomEffect = "BOTTOM_EFFECT",
    NoBackground = "NO_BACKGROUND",
    TopEffect = "TOP_EFFECT",
    Unicolor = "UNICOLOR",
}

export enum HorizontalTextAlign {
    Center = "CENTER",
    Left = "LEFT",
    Right = "RIGHT",
}

export enum VerticalTextAlign {
    Bottom = "BOTTOM",
    Center = "CENTER",
    Top = "TOP",
    UnderMosaic = "UNDER_MOSAIC",
}

export interface MosaicElement {
    folderId?:    number;
    href?:        TranslationSchema[];
    linkType?:    LinkType;
    resource?:    TranslationResourceSchema[];
    targetBlank?: boolean;
    title?:       TranslationSchema[];
}

export enum LinkType {
    Folder = "FOLDER",
    Mail = "MAIL",
    URL = "URL",
}

export interface TranslationResourceSchema {
    language:  Language;
    resource?: Resource;
    value?:    string;
}

export interface Resource {
    id?:  string;
    url?: string;
}

export enum Size {
    L = "L",
    M = "M",
    S = "S",
    Xl = "XL",
}
