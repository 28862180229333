import { KpkApiSheetField, KpkApiSheetFieldType } from "@keepeek/api-client";
import { MediaFieldCompatibleAdvancedSearch, MediaFieldCompatibleFacet } from "@keepeek/commons";
import { getI18n } from "react-i18next";

import { Type } from "../../../../models/configuration/components/businessFilter";
import { KAdminFields } from "../../../../models/configuration/types/kAdminFields";

export const getDisplayTypeOptions = (
  field: KAdminFields,
  sheetFields: KpkApiSheetField[] | undefined,
): Type[] => {
  // It's a media field ? (technical field)
  if (field.type === getI18n().t("mediaField")) {
    const ret: Type[] = [];
    if (Object.values(MediaFieldCompatibleAdvancedSearch).some((mf) => mf === field.id)) {
      ret.push(Type.Advanced);
    }
    if (Object.values(MediaFieldCompatibleFacet).some((mf) => mf === field.id)) {
      ret.push(Type.Facet);
    }
    return ret;
  } else {
    // It's a meta field ? (functional field)
    if (sheetFields?.find((s) => s.id === field.id)) {
      const sheetField = sheetFields?.find((s) => s.id === field.id);
      switch (sheetField?.type) {
        case KpkApiSheetFieldType.CheckBox:
        case KpkApiSheetFieldType.Datetime:
          return [Type.Advanced];
        case KpkApiSheetFieldType.SimpleList:
          return [Type.Facet];
        case KpkApiSheetFieldType.Date:
          return [Type.Advanced, Type.Facet];
        case KpkApiSheetFieldType.Email:
          return [Type.Advanced, Type.Facet];
        case KpkApiSheetFieldType.TextArea:
          return [Type.Facet, Type.Advanced];
        case KpkApiSheetFieldType.Text:
          return [Type.Facet, Type.Advanced];
        case KpkApiSheetFieldType.URL:
          return [Type.Advanced, Type.Facet];
        case KpkApiSheetFieldType.Thesaurus:
          return [Type.Facet, Type.FacetMulti];
        case KpkApiSheetFieldType.MultipleList:
          return [Type.Facet, Type.FacetMulti];
        default:
          return [];
      }
    } else {
      return [];
    }
  }
};
