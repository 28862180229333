import React from "react";

import { Widget, WidgetProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

const CustomEnumTranslation: Widget = function (props: WidgetProps) {
  const { t } = useTranslation("admin");
  const { SelectWidget } = props.registry.widgets;
  const prefixKey = props.options.i18nPrefix ? `${props.options.i18nPrefix}.` : "";
  const propsWithTranslations = {
    ...props,
    value:
      (props.value === undefined || props.value === "" || props.value === null) &&
      props.options.defaultValue
        ? props.options.defaultValue
        : props.value,
    options: {
      ...props.options,
      enumOptions: props.options.enumOptions?.map((enumOption) => {
        return {
          label: t(`${prefixKey}${enumOption.label.toLowerCase()}`),
          value: enumOption.value,
        };
      }),
    },
  };
  return <SelectWidget {...propsWithTranslations} />;
};

export default CustomEnumTranslation;
