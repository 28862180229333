/* eslint-disable @next/next/no-img-element */
import React from "react";

import { BaseComponentProps, SanitizeHtml } from "@keepeek/refront-components";
import { Box } from "@mui/material";
import clsx from "clsx";

import {
  DisplayMode,
  ImagePosition,
  KNewsWidget,
  VerticalTextAlign,
} from "../../../../../models/configuration/components/kNewsWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorNews = function ({
  testId = "WidgetCardSelectorNews",
  displayMode,
  backgroundColor,
  imagePosition,
  content,
  image,
  className,
  id,
  verticalTextAlign,
}: KNewsWidget & BaseComponentProps) {
  const locale = useCurrentLocale();
  const translatedContent = content?.find((u) => u.language === locale)?.value;
  const srcByLocale = image?.url?.find((u) => u.language === locale);
  let src = srcByLocale?.value;
  if (srcByLocale?.resource) {
    src = srcByLocale?.resource.url;
  }
  const altByLocale = image?.alt?.find((u) => u.language === locale);
  const alt = altByLocale?.value;
  if (!displayMode || displayMode === DisplayMode.Article) {
    const isRowMode =
      imagePosition === ImagePosition.ImageLeft || imagePosition === ImagePosition.ImageRight;
    return (
      <Box
        className={clsx("WidgetCardSelectorNewsArticle", className)}
        style={{
          width: "100%",
          backgroundColor,
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: isRowMode ? "row" : "column",
        }}
        data-testid={testId}
        title={id}
      >
        {imagePosition === ImagePosition.ImageTop && (
          <img
            src={src}
            alt={alt}
            style={{
              width: "100%",
              objectFit: "cover",
              height: "50%",
            }}
          />
        )}
        {imagePosition === ImagePosition.ImageLeft && (
          <>
            <Box
              sx={{
                width: `50%`,
              }}
            >
              <img
                src={src}
                alt={alt}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </>
        )}
        <Box
          style={{
            position: "relative",
            width: isRowMode ? "50%" : "100%",
            height: isRowMode ? "100%" : "50%",
          }}
        >
          {!!translatedContent && <SanitizeHtml html={translatedContent} />}
        </Box>
        {imagePosition === ImagePosition.ImageRight && (
          <>
            <Box
              sx={{
                width: `50%`,
              }}
            >
              <img
                src={src}
                alt={alt}
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </Box>
          </>
        )}
        {imagePosition === ImagePosition.ImageBottom && (
          <img
            src={src}
            alt={alt}
            style={{
              width: "100%",
              height: "50%",
              objectFit: "cover",
            }}
          />
        )}
      </Box>
    );
  }
  return (
    <Box
      className={clsx("WidgetCardSelectorNewsImage", className)}
      id={id}
      data-testid={testId}
      style={{
        position: "relative",
        width: "100%",
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
      }}
      title={id}
    >
      <Box
        style={{
          objectFit: "contain",
          position: "absolute",
          top:
            verticalTextAlign === VerticalTextAlign.Top
              ? "0"
              : verticalTextAlign === VerticalTextAlign.Center
              ? "50%"
              : "auto",
          bottom: verticalTextAlign === VerticalTextAlign.Bottom ? "0" : "auto",
          transform:
            verticalTextAlign === VerticalTextAlign.Center ? "translate(0%, -50%)" : "none",
          width: "100%",
        }}
      >
        {!!translatedContent && (
          <SanitizeHtml
            sanitizeOptions={{
              allowedAttributes: {
                span: ["style"],
              },
            }}
            html={translatedContent}
          />
        )}
      </Box>
    </Box>
  );
};

export default WidgetCardSelectorNews;
