import React from "react";

import { Popover, TextField, Box, SelectChangeEvent } from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/utils";
import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import { RgbaStringColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line jest/require-hook
extend([namesPlugin]);

export enum SectionId {
  RootPrimaryMain = "root_primary_main",
  RootSecondaryMain = "root_secondary_main",
}

/**
 * Custom Color picker
 */
const CustomColorPicker: Widget = function ({ onChange, value, label, formContext }: WidgetProps) {
  const { t } = useTranslation("admin");
  const colorRGB = colord(value).isValid() ? colord(value).toRgbString() : value;
  const [pickerAnchorEl, setPickerAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handlePickerOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setPickerAnchorEl(event.currentTarget);
  };
  const handlePickerClose = () => {
    setPickerAnchorEl(null);
  };
  const pickerOpen = Boolean(pickerAnchorEl);
  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<any>,
  ) => {
    // we do not control here what the user enters to give him time to write his color code (#FFF or red or etc...)
    // the control of the validity of the field will be carried out with the "blur" (=> handleInputBlur)
    onChange(event.target.value);
  };

  const handlePickerChange = (newColor: string) => {
    onChange(colord(newColor).toHex());
  };

  const handleInputBlur = () => {
    if (colord(value).isValid()) {
      // we must convert the color into a
      // format recognized by MUI (for example, if the user enters "orange",
      // we must convert into hex code before saving the value in the database)
      onChange(colord(value).toHex());
    } else {
      onChange("");
    }
  };

  return (
    <Box sx={{ padding: (theme) => theme.spacing(1) }}>
      <Popover
        open={pickerOpen}
        anchorEl={pickerAnchorEl}
        onClose={handlePickerClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableScrollLock={formContext && formContext.isFrontEdit}
      >
        <Box
          sx={{
            margin: 2,
          }}
        >
          <RgbaStringColorPicker color={colorRGB} onChange={handlePickerChange} />
        </Box>
      </Popover>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Box
          sx={{
            ...(value && { backgroundColor: value }),
            minWidth: 56,
            height: 56,
            mr: 0.5,
            borderRadius: 1,
            boxShadow: "rgb(50 50 93 / 11%) 0px 4px 6px, rgb(0 0 0 / 8%) 0px 1px 3px",
            border: "1px solid rgb(192 192 192)",
            cursor: "pointer",
          }}
          onClick={handlePickerOpen}
        />
        <TextField
          value={value}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          label={t(label)}
          InputLabelProps={{
            // we must manually control the shrink of the
            // field when its value is set from the picker otherwise the shrink does not work
            shrink: !!value,
          }}
          sx={{
            width: 500,
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomColorPicker;
