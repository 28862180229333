import React from "react";

import { Field, UiSchema } from "@rjsf/utils";
import { JSONSchema7Definition } from "json-schema";
import { pick } from "lodash";
import { useTranslation } from "react-i18next";

import { LinkType } from "../../../../models/configuration/components/kImageWidget";

const CustomMosaicElementField: Field = function (props) {
  const { t } = useTranslation("admin");
  const type: LinkType =
    (props.formData?.linkType && (props.formData?.linkType as LinkType)) ?? LinkType.Folder;

  const { ObjectField } = props.registry.fields;

  const urlSchema = {
    ...props.schema,
    properties: pick(
      props.schema.properties,
      "linkType",
      "title",
      "resource",
      "href",
      "targetBlank",
    ),
  };
  const urlUiSchema = {
    ...props.uiSchema,
    title: {
      "ui:field": "customMultilingualField",
      items: {
        empty: {
          "ui:widget": "hidden",
        },
        value: {
          "ui:widget": "textarea",
          "ui:options": {
            rows: 1,
          },
        },
      },
    },
    resource: {
      "ui:field": "customMultilingualField",
      items: {
        resource: {
          "ui:field": "resourceSelect",
          "ui:options": {
            type: "image",
          },
        },
      },
    },
    href: {
      "ui:field": "customMultilingualField",
      items: {
        empty: {
          "ui:widget": "hidden",
        },
        value: {
          "ui:title": t("mosaic-element.redirection.url.label"),
          "ui:widget": "textarea",
          "ui:options": {
            rows: 1,
          },
        },
      },
    },
  };

  const emailSchema = {
    ...props.schema,
    properties: pick(props.schema.properties, "linkType", "title", "resource", "href"),
  };
  const emailUiSchema = {
    ...props.uiSchema,
    title: {
      "ui:field": "customMultilingualField",
      items: {
        empty: {
          "ui:widget": "hidden",
        },
        value: {
          "ui:widget": "textarea",
          "ui:options": {
            rows: 1,
          },
        },
      },
    },
    resource: {
      "ui:field": "customMultilingualField",
      items: {
        resource: {
          "ui:field": "resourceSelect",
          "ui:options": {
            type: "image",
          },
        },
      },
    },
    href: {
      "ui:field": "customMultilingualField",
      items: {
        value: {
          "ui:field": "customMailLink",
        },
      },
    },
  };

  const folderSchema = {
    ...props.schema,
    properties: pick(props.schema.properties, "linkType", "folderId"),
  };
  const folderUiSchema = {
    ...props.uiSchema,
    folderId: {
      "ui:widget": "folders",
    },
  };

  if (type === LinkType.Mail) {
    return (
      <ObjectField
        {...props}
        schema={emailSchema as { [key: string]: JSONSchema7Definition }}
        uiSchema={emailUiSchema as UiSchema}
      />
    );
  } else if (type === LinkType.Folder) {
    return (
      <ObjectField
        {...props}
        schema={folderSchema as { [key: string]: JSONSchema7Definition }}
        uiSchema={folderUiSchema as UiSchema}
      />
    );
  } else {
    return (
      <ObjectField
        {...props}
        schema={urlSchema as { [key: string]: JSONSchema7Definition }}
        uiSchema={urlUiSchema as UiSchema}
      />
    );
  }
};

export default CustomMosaicElementField;
