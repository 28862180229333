import React, { ComponentType, useEffect, useMemo, useState } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, Icon, IconProps } from "@mui/material";
import clsx from "clsx";
import cloneDeep from "lodash/cloneDeep";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { TreeLeafs } from "./TreeLeafs";
import { TreeEditOption } from "./types";

export type TreeEditProps<T> = {
  /**
   * Array items
   */
  items: TreeEditOption<T>[];
  /**
   * Callback triggered when tree have been updated
   * @param items new items
   */
  onChange: (items: TreeEditOption<T>[]) => void;
  /**
   * Allow deleting an item
   */
  allowRemove?: boolean;
  /**
   * Edit an item
   * @param event click event
   * @param item item to edit
   */
  onEdit?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    item: TreeEditOption<T>,
    parentItem?: TreeEditOption<T>,
  ) => void;
  /**
   * Item removed
   * @param event click event
   * @param item item to edit
   */
  onRemove?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: TreeEditOption<T>,
    parentItem?: TreeEditOption<T>,
  ) => void;
  /**
   * Toggle trigered
   * @param event click event
   * @param item item to toggle
   */
  onToggle?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: TreeEditOption<T>,
    parentItem?: TreeEditOption<T>,
  ) => void;
  /**
   * Indicated a drag start
   * @returns draging item
   */
  onDragStart?: (item: TreeEditOption<T>) => void;
  /**
   * Indicated a drag end
   * @returns draging item
   */
  onDragEnd?: (item: TreeEditOption<T>) => void;
  /**
   * Item add section
   * @param previousItemIndex index of item just before the new one
   */
  onAddParentItem?: (previousItemIndex: number | undefined) => void;
  /**
   * Item add filter
   * @param previousItemIndex index of item just before the new one
   */
  onAddItem?: (previousItemIndex: number | undefined, childIndex: number | undefined) => void;
  /**
   * Icon for overides
   */
  KIcon?: ComponentType<{} & IconProps>;
} & BaseComponentProps;

const TreeEdit = function TreeEdit<T>({
  id,
  className,
  sx,
  testId = "TreeEdit",
  items,
  onChange,
  allowRemove = true,
  onEdit,
  onRemove,
  onToggle,
  onDragStart,
  onAddParentItem,
  onAddItem,
  KIcon = Icon,
}: React.PropsWithChildren<TreeEditProps<T>>) {
  // Clone items to avoid mutating original array
  const [newItems, setNewItems] = useState<TreeEditOption<T>[]>(cloneDeep(items));
  useEffect(() => {
    setNewItems(cloneDeep(items));
  }, [items]);

  const handDragEnd = () => onChange(newItems);

  const pathEdit = useMemo(() => [], []);
  return (
    <Box className={clsx("TreeEdit", className)} sx={sx} id={id} data-testid={testId}>
      <DndProvider backend={HTML5Backend}>
        <TreeLeafs<T>
          items={newItems}
          allowRemove={allowRemove}
          onEdit={onEdit}
          onRemove={onRemove}
          onToggle={onToggle}
          onDragStart={onDragStart}
          onDragEnd={handDragEnd}
          onAddParentItem={onAddParentItem}
          onAddItem={onAddItem}
          KIcon={KIcon}
          setNewItems={setNewItems}
          pathEdit={pathEdit}
        />
      </DndProvider>
    </Box>
  );
};

export default TreeEdit;
