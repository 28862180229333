export interface FooterWidget {
    backgroundColor?:                 string;
    borderColor?:                     string;
    color?:                           string;
    container?:                       boolean;
    id?:                              string;
    languageSwitcherPosition?:        LanguageSwitcherPosition;
    linksByLocale?:                   LocaleLinksSchema[];
    linksColorOnHover?:               string;
    linksDirection?:                  Direction;
    linksListHeight?:                 string;
    linksListWidth?:                  string;
    linksPadding?:                    number;
    linksPosition?:                   LinksPosition;
    linksSize?:                       number;
    linksUnderlined?:                 boolean;
    linksUnderlinedOnHover?:          boolean;
    shareWithConfig?:                 boolean;
    socialNetworks?:                  SocialNetworks;
    socialNetworksLanguageDirection?: Direction;
    verticalPadding?:                 number;
}

export enum LanguageSwitcherPosition {
    Left = "LEFT",
    None = "NONE",
    Right = "RIGHT",
}

export interface LocaleLinksSchema {
    links:  Link[];
    locale: Locale;
}

export interface Link {
    href?:         string;
    label:         string;
    linkType?:     LinkType;
    openInNewTab?: boolean;
}

export enum LinkType {
    Didomi = "DIDOMI",
    Folder = "FOLDER",
    Mail = "MAIL",
    URL = "URL",
}

export enum Locale {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    Hu = "hu",
    It = "it",
    Nl = "nl",
    Pl = "pl",
    Pt = "pt",
    Ro = "ro",
}

export enum Direction {
    Horizontal = "HORIZONTAL",
    Vertical = "VERTICAL",
}

export enum LinksPosition {
    Center = "CENTER",
    Left = "LEFT",
    Right = "RIGHT",
}

export interface SocialNetworks {
    color?:               string;
    socialNetworksItems?: SocialNetworksItem[];
}

export interface SocialNetworksItem {
    iconsWithLinks?: IconsWithLinksByLocaleSchema[];
    label?:          string;
    language?:       Locale;
}

export interface IconsWithLinksByLocaleSchema {
    href:  string;
    icon:  string;
    label: string;
}
