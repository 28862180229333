import React from "react";

import { SanitizeHtml } from "@keepeek/refront-components";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Widget } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { Namespace } from "../../../../models/configuration/labels";
import { frontEditCurrentEditKeysState } from "../../../../providers/frontEdit/atoms/frontEditCurrentEditKeys";
import { getOriginalTranslationByLanguage } from "../../../../providers/frontEdit/selectors/originalTranslations";
import { CustomTranslationWidget } from "../CustomTranslationWidget";

const CustomFrontEditLabelWidget: Widget = function (props) {
  const { t } = useTranslation("admin");

  const currentEditKeys = useRecoilValue(frontEditCurrentEditKeysState);

  const currentEditLabel = currentEditKeys[0].label?.namespaceLabelKey || "";

  const splitedLabelKey = currentEditLabel.split("/");

  let labelKey: string = "";
  let namespace: Namespace | undefined = undefined;
  if (splitedLabelKey.length === 2) {
    namespace = splitedLabelKey[0] as Namespace;
    labelKey = splitedLabelKey[1];
  } else {
    labelKey = splitedLabelKey[0];
  }

  const originalTranslationQuery = useRecoilValueLoadable(
    getOriginalTranslationByLanguage({ lng: props.value, namespace }),
  );

  const originalTranslation =
    originalTranslationQuery.state === "hasValue" ? originalTranslationQuery.contents : "";

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "5rem auto",
        columnGap: "2rem",
        gridTemplateRows: "min-content min-content",
      }}
    >
      <CustomTranslationWidget
        sx={{
          gridColumn: "1",
          gridRow: "2",
          "& .MuiOutlinedInput-input": {
            textTransform: "uppercase",
            fontWeight: "bold",
            textAlign: "center",
          },
          width: "unset",
          height: "unset",
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
        }}
        {...props}
      />
      <Typography sx={{ gridColumn: "2", gridRow: "1" }} variant="subtitle1">
        {t("labels.value.original.label")}
      </Typography>
      <Box
        sx={{
          gridColumn: "2",
          gridRow: "2",
          border: "1px solid #cbcbcb",
          borderRadius: (theme) => theme.spacing(0.5),
          padding: "16px 14px ",
          width: "100%",
          color: (theme) => theme.palette.grey[600],
          background: (theme) => theme.palette.grey[200],
        }}
      >
        <SanitizeHtml html={originalTranslation[labelKey]} />
      </Box>
    </Box>
  );
};

export default CustomFrontEditLabelWidget;
