import { FC } from "react";

import { useStatsQueueListener } from "@keepeek/commons";
import { withSuspense } from "@keepeek/refront-components";
import { useRecoilValue } from "recoil";

import { activeBusinessFiltersFromURLSelector } from "../../providers/businessFilter/selectors/activeFilters";
import { initConfigApiAtom } from "../../providers/config/atoms/init";
import { configSectionComponentBusinessFilterSelector } from "../../providers/config/selectors";
import { businessFiltersConfigurationAdaptor } from "../../providers/config/utils";

/**
 * Component usefull for "suspense" to avoid flash of the whole app
 * @author JBO
 *
 * @returns {*}
 */
const StatsListener: FC = function () {
  const filters = useRecoilValue(activeBusinessFiltersFromURLSelector);

  const initConfig = useRecoilValue(initConfigApiAtom);
  const filtersConfiguration =
    useRecoilValue(configSectionComponentBusinessFilterSelector)?.filters ?? null;

  // Stats queue listener, used to send stats with resolved count
  useStatsQueueListener(
    filters,
    businessFiltersConfigurationAdaptor(filtersConfiguration),
    initConfig?.clientIp,
  );
  return null;
};
export default withSuspense(StatsListener);
