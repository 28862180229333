import { EditorType } from "@keepeek/refront-components";
import { atom } from "recoil";

export type CurrentEditKey = {
  configSection: string;
  widgetId?: string;
  label?: {
    namespaceLabelKey: string;
    editorType?: EditorType;
  };
};

export const frontEditCurrentEditKeysState = atom<CurrentEditKey[]>({
  key: "FrontEditCurrentEditKeysState",
  default: [],
});
