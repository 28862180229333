import React from "react";

import { Alert } from "@mui/material";
import { Field, FieldProps, RJSFSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

import { useVisualAlertsEnabled } from "./useVisualAlertEnabled";

export const CustomTagsField: Field = function (props: FieldProps) {
  const { t } = useTranslation();
  const { ObjectField } = props.registry.fields;
  const selectedTags = props.formData?.tagsToDisplayId;
  const isVisualAlertsEnabled = useVisualAlertsEnabled();
  const onlyTitleSchema: RJSFSchema = {
    title: props.schema.title,
  };

  const allSchemaWithoutTitle: RJSFSchema = {
    ...props.schema,
    title: undefined,
  };

  return (
    <>
      <ObjectField {...props} schema={onlyTitleSchema} />
      {isVisualAlertsEnabled && (!selectedTags || selectedTags?.length === 0) && (
        <Alert severity="warning">{t("admin:rjsf.custom-tags-field.alert-message")}</Alert>
      )}
      <ObjectField {...props} schema={allSchemaWithoutTitle} />
    </>
  );
};
