import { useRecoilValue } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../../components/layout/adminMenu";
import { Element } from "../../../../models/configuration/pages/element";
import { Search } from "../../../../models/configuration/pages/search";
import { Share } from "../../../../models/configuration/pages/share";
import { ShareElement } from "../../../../models/configuration/pages/shareElement";
import { configSectionDataByPathSelector } from "../../../../providers/config/selectors";

/**
 * Hook to check if visual alerts are enabled on this refront.
 *
 * @returns {boolean} - True if visual alerts are enabled, false otherwise.
 */
export const useVisualAlertsEnabled = (): boolean => {
  const pageElementConfig: Element | undefined = useRecoilValue(
    configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.PAGES_ELEMENT),
  );
  const pageElementShareConfig: (ShareElement & Element) | undefined = useRecoilValue(
    configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.PAGES_SHARE_ELEMENT),
  );
  const pageSearchConfig: Search | undefined = useRecoilValue(
    configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.PAGES_SEARCH),
  );
  const pageSearchShareConfig: (Share & Search) | undefined = useRecoilValue(
    configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.PAGES_SHARE),
  );

  // Element page
  if (pageElementConfig?.visualAlerts && pageElementConfig?.visualAlerts?.length > 0) {
    return true;
  }

  // Share element page
  if (
    pageElementShareConfig &&
    pageElementShareConfig?.inherit === "CUSTOM" &&
    pageElementShareConfig?.visualAlerts &&
    pageElementShareConfig?.visualAlerts?.length > 0
  ) {
    return true;
  }

  // Search page
  if (pageSearchConfig?.visualAlerts && pageSearchConfig?.visualAlerts?.length > 0) {
    return true;
  }

  // Search share page
  if (
    pageSearchShareConfig &&
    pageSearchShareConfig?.inherit === "CUSTOM" &&
    pageSearchShareConfig?.visualAlerts &&
    pageSearchShareConfig?.visualAlerts?.length > 0
  ) {
    return true;
  }

  return false;
};
