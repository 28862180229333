export interface Dataview {
    contactSheets?:       boolean;
    displayCount?:        boolean;
    displayMode?:         DisplayMode;
    displayModes?:        DisplayMode[];
    elementIcons?:        ElementIcon[];
    elementImageSize?:    boolean;
    listViewSize?:        ListViewSize;
    maxElementsPerPage?:  number;
    openBusinessFilters?: boolean;
}

export enum DisplayMode {
    List = "list",
    Masonry = "masonry",
    Mosaic = "mosaic",
}

export enum ElementIcon {
    Image = "image",
    PDF = "pdf",
    Video = "video",
}

export enum ListViewSize {
    L = "L",
    Xl = "XL",
}
