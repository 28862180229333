import React, { FC } from "react";

import { FieldProps } from "@rjsf/utils";

import MailLink, { MailLinkData } from "../components/MailLink";
import { convertToMailToFormat, extractToMailLinkData } from "../utils/custom-links-field-utils";

const CustomMailLink: FC<React.PropsWithChildren<FieldProps>> = function ({ formData, onChange }) {
  const href: string = formData ?? "";
  const mailLinkData: MailLinkData = extractToMailLinkData(href);

  const handleChange = (data: string) => onChange(data);

  return (
    <MailLink
      recipients={mailLinkData.recipients}
      body={mailLinkData.body}
      subject={mailLinkData.subject}
      onChange={(data) => handleChange(convertToMailToFormat(data))}
    />
  );
};

export default CustomMailLink;
