export interface Template {
    animation?:            Animation;
    autoTopMargin?:        boolean;
    backgroundColor?:      string;
    classNames?:           string;
    columns?:              Column[];
    columnSpacing?:        number;
    container?:            boolean;
    gridSpacing?:          number;
    isCollapsed?:          boolean;
    isFinalized?:          boolean;
    itemTitle?:            string;
    margin?:               number;
    padding?:              number;
    rowSpacing?:           number;
    verticallyExtendable?: boolean;
}

export interface Animation {
    animationType?: AnimationType;
}

export enum AnimationType {
    FadeInBottom = "FADE_IN_BOTTOM",
    NoAnimation = "NO_ANIMATION",
}

export interface Column {
    backgroundColor?: string;
    borderColor?:     string;
    borderWidth?:     number;
    breakpoints?:     Breakpoint;
    classNames?:      string;
    itemTitle?:       string;
    widgets?:         WidgetSchema[];
}

export interface Breakpoint {
    lg?: number;
    md?: number;
    sm?: number;
    xl?: number;
    xs?: number;
}

export interface WidgetSchema {
    classNames?: string;
    id:          string;
    type:        Type;
}

export enum Type {
    Associations = "associations",
    Attachments = "attachments",
    Baskets = "baskets",
    Custom = "custom",
    Edito = "edito",
    Footer = "footer",
    Header = "header",
    Image = "image",
    Menu = "menu",
    Mosaic = "mosaic",
    News = "news",
    Report = "report",
    Search = "search",
    SectionTitle = "sectionTitle",
    Sheet = "sheet",
    Spacer = "spacer",
    Tabs = "tabs",
    Video = "video",
}
