import React from "react";

import { useGetTags } from "@keepeek/commons";
import { FieldTags, SanitizeHtml } from "@keepeek/refront-components";
import { Box, Typography } from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

const CustomTagsSelector: Widget = function ({ onChange, value, label }: WidgetProps) {
  const { t } = useTranslation();
  const allTags = useGetTags();

  const handleChange = (updatedTag: { tagId?: number; enabled: boolean }) => {
    if (updatedTag.tagId === undefined) {
      return;
    }
    let updatedTags: number[];
    if (updatedTag.enabled) {
      // Add new tag to list
      updatedTags = [...value, updatedTag.tagId];
    } else {
      // Remove tag to list
      updatedTags = value.filter((id: number) => id !== updatedTag.tagId);
    }
    onChange(updatedTags);
  };

  return (
    <>
      <Typography variant="subtitle1">
        <SanitizeHtml html={t(label)} />
      </Typography>
      <Box sx={{ width: "fit-content", maxWidth: "60%" }}>
        <FieldTags tags={allTags} enabledTagIdValues={value} onChange={handleChange} />
      </Box>
    </>
  );
};

export default CustomTagsSelector;
