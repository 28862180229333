import { Direction, FooterWidget } from "../models/configuration/components/footerWidget";
import { Footer, LinksDirection } from "../models/configuration/definitions/footer";

/*
 * Get converted footer widget
 */
export function getConvertFooterWidget(oldFooter: Footer | null): FooterWidget | undefined {
  if (oldFooter) {
    const footerWidget: FooterWidget = {
      ...oldFooter,
      linksDirection:
        oldFooter.linksDirection === LinksDirection.Horizontal
          ? Direction.Horizontal
          : Direction.Vertical,
    };

    return footerWidget;
  }
}
