import { JSONSchema7 } from "json-schema";
import { isBoolean } from "lodash";

import {
  BusinessFilterFilter,
  Type,
} from "../../../../../models/configuration/components/businessFilter";

/**
 * returns the RJSF UI JSONSchema to only display the title of the section containing filters
 */
export const getRJSFUISchemaSectionTitle = () => {
  return {
    "ui:title": "",
    title: {
      "ui:field": "customMultilingualField",
      items: {
        "frontEdit:right": "administrator",
      },
    },
  };
};

/**
 * returns the RJSF UI JSONSchema to display a filter.
 * The display of the component will be different depending on the type of filter chosen.
 * @param businessFilterFilter the filter allowing the schema to be conditioned according to its content
 */
export const getRJSFUISchemaFilters = (businessFilterFilter?: Partial<BusinessFilterFilter>) => {
  return {
    "ui:title": "",
    "ui:collapseEnabled": "false",
    name: {
      "ui:widget": "fields",
      "ui:options": {
        ...(businessFilterFilter?.type && { fieldListDisplayType: businessFilterFilter?.type }),
      },
    },
    type: {
      "ui:widget": "enumTranslation",
      "ui:options": {
        i18nPrefix: "rjsf.enum.business-filter.type",
      },
    },
    ...((!businessFilterFilter?.type || businessFilterFilter.type === Type.Advanced) && {
      hideFacetOccurrences: { "ui:widget": "hidden" },
      orderDirectionFacetValues: { "ui:widget": "hidden" },
      orderTypeFacetValues: { "ui:widget": "hidden" },
    }),
    ...((businessFilterFilter?.type === Type.Facet ||
      businessFilterFilter?.type === Type.FacetMulti) && {
      orderDirectionFacetValues: {
        "ui:widget": "enumTranslation",
        "ui:options": {
          i18nPrefix: "rjsf.enum.order-direction",
          defaultValue: "DESC",
        },
      },
      orderTypeFacetValues: {
        "ui:widget": "enumTranslation",
        "ui:options": {
          i18nPrefix: "rjsf.enum.business-filter.orderTypeFacetValues",
          defaultValue: "occurrences",
        },
      },
    }),
  };
};

/**
 * allows you to extract a specific part of the schema in order to return the part concerning the section title
 * @param schema original JSONSchema
 */
export const getRJSFSchemaSectionTitle = (schema: JSONSchema7): JSONSchema7 => {
  if (
    !schema ||
    !schema?.anyOf ||
    !schema?.anyOf[1] ||
    isBoolean(schema.anyOf[1]) ||
    !schema.anyOf[1].properties
  ) {
    return {};
  }
  const { title } = schema.anyOf[1].properties;
  return {
    properties: {
      title,
    },
  };
};

/**
 * allows you to extract a specific part of the schema in order to return the part concerning the filters
 * @param schema original JSONSchema
 */
export const getRJSFSchemaFilters = (schema: JSONSchema7): JSONSchema7 => {
  if (
    !schema ||
    !schema?.anyOf ||
    !schema?.anyOf[0] ||
    isBoolean(schema.anyOf[0]) ||
    !schema.anyOf[0].properties
  ) {
    return {};
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, ...rest } = schema?.anyOf[0].properties;
  return {
    properties: {
      ...rest,
    },
  };
};

export const isSectionTitle = (businessFilterFilter?: BusinessFilterFilter): boolean =>
  !!businessFilterFilter?.title;
