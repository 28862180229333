import { CurrentEditKey } from "../../providers/frontEdit/atoms/frontEditCurrentEditKeys";

export const getPathFromSectionDataKey = (key: CurrentEditKey) => {
  if (key.widgetId) {
    return `${key.configSection}/${key.widgetId}`;
  } else if (key.label && key.label.namespaceLabelKey) {
    return `${key.configSection}/${key.label.namespaceLabelKey}`;
  } else {
    return key.configSection;
  }
};

export const getNamespaceLabelKey = (labelKey: string, namespace?: string) => {
  if (namespace) {
    return `${namespace}/${labelKey}`;
  }
  return labelKey;
};
