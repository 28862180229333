import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Icon, IconProps } from "@mui/material";
import clsx from "clsx";

export type KMaterialIconProps = { iconKey: string } & IconProps & BaseComponentProps;

/**
 * Keepeek Material icon
 */
export const KMaterialIcon = function ({
  iconKey,
  className,
  testId = "KMaterialIcon",
  ...rest
}: KMaterialIconProps) {
  return (
    <Icon
      className={clsx("KMaterialIcon", className)}
      sx={{ overflow: "unset" }}
      data-testid={testId}
      {...rest}
      baseClassName={undefined} // Set to undefined because if it is set by 'rest', the material icon will not be displayed
    >
      {iconKey}
    </Icon>
  );
};
