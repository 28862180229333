import React, { FC } from "react";

import { KButton } from "@keepeek/refront-components";
import { Badge, Box, Paper, Typography } from "@mui/material";
import { ArrayFieldTemplateProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

import CustomDescriptionField from "../CustomDescriptionField";
import CustomArrayFieldTemplateItems from "./CustomArrayFieldTemplateItems";

export const CustomArrayFieldTemplate: FC<React.PropsWithChildren<ArrayFieldTemplateProps>> =
  function (props: ArrayFieldTemplateProps) {
    const { t } = useTranslation();
    return (
      <Box
        sx={{
          ".array-item-add": {
            fontSize: "0",
            "&:after": {
              content: `"${t("action.add").replace('"', "")}"`,
              fontSize: "1rem",
            },
          },
          /*
           * we hide the title of the array items :
           * exemple of id content => root_translation_0-title / root_content_0-title
           */
          "& div[id*='root_'][id$='-title']": {
            display: "none",
          },
        }}
      >
        <Paper sx={{ padding: 2, width: "100%" }} variant="outlined">
          <Badge badgeContent={props.items.length} color="primary">
            <Typography variant="h6" component="div">
              {props.title}
              {props.required ? "*" : ""}
            </Typography>
          </Badge>
          {props.schema.description && (
            <CustomDescriptionField
              description={props.schema.description}
              id={props.idSchema.$id}
              registry={props.registry}
              schema={props.schema}
            />
          )}
          <CustomArrayFieldTemplateItems items={props.items} name={props.title} />
          {props.canAdd && (
            <Box sx={{ paddingTop: 1 }}>
              <KButton fullWidth variant="outlined" onClick={props.onAddClick}>
                {`${t("action.add")}: "${props.title}"`}
              </KButton>
            </Box>
          )}
        </Paper>
      </Box>
    );
  };
