import { useEffect, useMemo } from "react";

import { currentLocaleAtom } from "@keepeek/commons";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { WidgetConfigurations } from "../../../containers/widgets/models";
import logger from "../../../lib/logger-utils";
import { Template } from "../../../models/configuration/definitions/template";
import { Global, Visibility } from "../../../models/configuration/global";
import { TemplateSchema } from "../../../models/configuration/pages/home";
import { useLoadableErrorLogger } from "../../utils";
import { configSectionPeristenceAtom } from "../atoms/init";
import { configSectionDataByPathSelector, configSectionKTemplateByPosition } from "../selectors";
import { isPageVisible, KTemplatesTemplatesName } from "../utils";

type UseKTemplatesConfParams = {
  templatePagePosition?: CONFIGURATION_SECTION_PATH;
  templatesName?: KTemplatesTemplatesName;
  defaultTemplates?: TemplateSchema[];
};

type UseKTemplatesConfReturn = {
  templates: Template[] | undefined;
  widgetsConfiguration: WidgetConfigurations | undefined;
  loading: boolean;
};

export const useKTemplatesConf = ({
  templatesName = KTemplatesTemplatesName.Templates,
  templatePagePosition,
  defaultTemplates = [],
}: UseKTemplatesConfParams): UseKTemplatesConfReturn => {
  const locale = useRecoilValue(currentLocaleAtom);
  let defaultTemplatesStringify: string | undefined;
  try {
    defaultTemplatesStringify = JSON.stringify(defaultTemplates);
  } catch (error) {
    logger.error("useKTemplatesConf - can't stringify default templates", error);
  }

  const configQuery = useRecoilValueLoadable(
    configSectionKTemplateByPosition({
      locale,
      templatePagePosition,
      templatesName,
      defaultTemplatesStringify,
    }),
  );
  useLoadableErrorLogger(configQuery, `useKTemplatesConf configQuery ${templatesName}`);

  const loading = configQuery.state === "loading";

  const { templates, widgetsConfiguration } =
    configQuery.state === "hasValue"
      ? configQuery.contents
      : { templates: undefined, widgetsConfiguration: undefined };

  return {
    templates,
    widgetsConfiguration,
    loading,
  };
};

type UseConfiguration<T, K extends undefined | true = undefined> = K extends true
  ? (T | undefined) & { isLoading: boolean }
  : T | undefined;

/**
 * Serve admin configuration with persistence mecanism during loading state
 * @param configSectionName CONFIGURATION_SECTION_PATH: path in admin configuration
 * @returns admin config or undefined
 */
export function useConfiguration<T, K extends undefined | true = undefined>(
  configSectionName: CONFIGURATION_SECTION_PATH,
  withLoadingState?: K,
): UseConfiguration<T, K> {
  const query = useRecoilValueLoadable<T>(configSectionDataByPathSelector(configSectionName));
  const [persistor, setPersistor] = useRecoilState<T>(
    configSectionPeristenceAtom(configSectionName),
  );

  useEffect(() => {
    if (query.state === "hasValue") {
      setPersistor(query.contents);
    }
  }, [query.contents, query.state, setPersistor]);

  return useMemo(() => {
    if (withLoadingState === true) {
      return {
        ...persistor,
        isLoading: query.state === "loading",
      } as UseConfiguration<T, true>;
    } else {
      return persistor as UseConfiguration<T, K>;
    }
  }, [persistor, query.state, withLoadingState]);
}

/**
 * Use page visible
 */
export const usePageVisible = () => {
  const config = useConfiguration<Global>(CONFIGURATION_SECTION_PATH.GLOBAL);

  return {
    homeVisible: isPageVisible(config, Visibility.Home),
    elementVisible: isPageVisible(config, Visibility.Element),
    searchVisible: isPageVisible(config, Visibility.Search),
  };
};
