import React from "react";

import { EditableSanitizeHtmlProps } from "@keepeek/refront-components";

import EditableSanitizeHtml from "../../../containers/FrontEdit/EditableSanitizeHtml";

export type UseFrontEditLabelWrapper = {
  FrontEditComponentsLabelWrapper: any;
};

export default function useFrontEditLabelWrapper(): UseFrontEditLabelWrapper {
  const FrontEditComponentsLabelWrapper = React.useMemo(() => {
    const LabelWrapper: React.FC<React.PropsWithChildren<EditableSanitizeHtmlProps>> = function ({
      labelKey,
      namespace,
      editorType,
      labelOptions,
      sanitizeOptions,
      className,
      hideIfEmpty,
    }) {
      return (
        <EditableSanitizeHtml
          labelKey={labelKey}
          namespace={namespace}
          editorType={editorType}
          labelOptions={labelOptions}
          sanitizeOptions={sanitizeOptions}
          className={className}
          hideIfEmpty={hideIfEmpty}
        />
      );
    };
    return LabelWrapper;
  }, []);

  return {
    FrontEditComponentsLabelWrapper,
  };
}
