import React, { FC } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

import { KIcon } from "../../../../components/common/KIcon";

const materialIcons: string[] = [
  "flash_auto",
  "lock_open",
  "assignment_ind",
  "person_add",
  "shopping_basket",
  "filter_list",
  "edit",
  "clear_all",
  "clear",
  "delete",
  "picture_as_pdf",
  "attachment",
  "cloud_download",
  "add_photo_alternate",
  "arrow_back",
  "image",
  "play_circle_outline",
  "arrow_upward",
  "arrow_downward",
  "menu",
  "expand_more",
  "visibility_on",
  "more_vert",
  "content_copy",
  "file_upload",
  "visibility",
  "link",
  "import_export",
  "file_download",
  "share",
  "view_module",
  "person_add_alt",
  "groups_2",
  "remove",
  "replay",
  "open_in_new",
];

const fontAwesomeIcons: string[] = [
  "fa-magnifying-glass",
  "fa-circle-info",
  "fa-chevron-down",
  "fa-chevron-up",
  "fa-folder-tree",
  "fa-share",
  "fa-crop-simple",
  "fa-file-circle-plus",
  "fa-triangle-exclamation",
  "fa-camera",
  "fa-file-image",
  "fa-images",
  "fa-user",
  "fa-down-left-and-up-right-to-center",
  "fa-up-right-and-down-left-from-center",
  "fa-up-right-from-square",
  "fa-link",
  "fa-download",
  "fa-ellipsis-vertical",
  "fa-pen-to-square",
  "fa-clone",
  "fa-trash",
  "fa-square-plus",
  "fa-pen",
  "fa-grip-vertical",
  "fa-refresh",
  "fa-arrow-right",
  "fa-arrow-up",
  "fa-grip",
  "fa-arrow-down",
  "fa-circle-chevron-left",
  "fa-question-circle",
  "fa-basket-shopping",
  "fa-bars",
  "fa-angles-left",
  "fa-angles-right",
];

const CustomIconsField: FC<React.PropsWithChildren<FieldProps>> = function (props) {
  const { ArrayField } = props.registry.fields;
  const { t } = useTranslation("admin");

  return (
    <>
      <Accordion sx={{ marginBottom: "24px" }}>
        <AccordionSummary
          expandIcon={<KIcon baseClassName="fas" className="fa-regular fa-chevron-down"></KIcon>}
        >
          <KIcon
            baseClassName="fas"
            className="fa-regular fa-circle-info"
            color="info"
            sx={{ padding: (theme) => theme.spacing(1) }}
          ></KIcon>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={"h5"} component={"div"}>
              {t("rjsf.icons.information-panel.title")}
            </Typography>
            <Typography>{t("rjsf.icons.information-panel.description")}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", gap: 6 }}>
            <TableContainer>
              <Table>
                <TableHead sx={{ background: "#EEEEEE" }}>
                  <TableRow>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      {t("rjsf.icons.material")}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }} align="right">
                      {t("rjsf.icons.key")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materialIcons.map((materialIcon) => (
                    <TableRow key={materialIcon}>
                      <TableCell>
                        <Icon>{materialIcon}</Icon>
                      </TableCell>
                      <TableCell align="right">{materialIcon}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table>
                <TableHead sx={{ background: "#EEEEEE" }}>
                  <TableRow>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      {t("rjsf.icons.font-awesome")}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }} align="right">
                      {t("rjsf.icons.key")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fontAwesomeIcons.map((fontAwesomeIcon) => (
                    <TableRow key={fontAwesomeIcon}>
                      <TableCell>
                        <Icon baseClassName="fas" className={fontAwesomeIcon} />
                      </TableCell>
                      <TableCell align="right">{fontAwesomeIcon}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ArrayField {...props} />
    </>
  );
};

export default CustomIconsField;
