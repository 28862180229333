import React, { ComponentType, Dispatch, SetStateAction } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, IconProps, Typography } from "@mui/material";
import clsx from "clsx";

import { TreeAdd } from "./TreeAdd";
import { TreeHeader } from "./TreeHeader";
import { TreeItem } from "./TreeItem";
import { TreeLeafWithChildrens } from "./TreeLeafWithChildrens";
import { genericMemo, TreeEditOption } from "./types";

import { TreeEditProps } from ".";

export type TreeLeafsProps<T> = {
  items: TreeEditProps<T>["items"];
  allowRemove?: TreeEditProps<T>["allowRemove"];
  onEdit?: TreeEditProps<T>["onEdit"];
  onRemove?: TreeEditProps<T>["onRemove"];
  onAddItem?: TreeEditProps<T>["onAddItem"];
  onAddParentItem?: TreeEditProps<T>["onAddParentItem"];
  onToggle?: TreeEditProps<T>["onToggle"];
  onDragStart?: TreeEditProps<T>["onDragStart"];
  onDragEnd?: TreeEditProps<T>["onDragEnd"];
  KIcon: ComponentType<{} & IconProps>;
  /**
   * Represents current item parents hierarchy in tree.
   * For example, it could be [1, 2, 3] meaning current item parent path is items[1].childrens[2].childrens[3]
   */
  pathEdit: number[];
  setNewItems: Dispatch<SetStateAction<TreeEditOption<T>[]>>;
  parentItem?: TreeEditOption<T>;
} & BaseComponentProps;

export const TreeLeafs = genericMemo(function TreeLeafs<T>({
  id,
  className,
  sx,
  testId = "TreeLeafs",
  items,
  allowRemove,
  onEdit,
  onRemove,
  onToggle,
  onDragStart,
  onDragEnd,
  onAddItem,
  onAddParentItem,
  KIcon,
  pathEdit,
  setNewItems,
  parentItem,
}: React.PropsWithChildren<TreeLeafsProps<T>>) {
  return (
    <Box className={clsx("TreeLeafs", className)} id={id} data-testid={testId} sx={sx}>
      {parentItem && items.length === 0 && (
        <TreeAdd
          sx={{ pl: "38px", pr: "8px" }}
          onAddItem={onAddItem}
          previousItemIndex={0}
          parentItem={parentItem}
          KIcon={KIcon}
        />
      )}
      {items.map((item: TreeEditOption<T>, index) => (
        <Box key={item.id}>
          <TreeItem<T>
            key={`item-${item.id}`}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            KIcon={KIcon}
            pathEdit={pathEdit}
            index={index}
            item={item}
            setNewItems={setNewItems}
          >
            {item?.children === undefined && (
              <TreeHeader<T>
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                  border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                }}
                item={item}
                allowRemove={allowRemove}
                onEdit={onEdit}
                onRemove={onRemove}
                KIcon={KIcon}
                label={
                  <Typography variant="body2" sx={{ fontWeight: "bold", wordBreak: "break-word" }}>
                    {item.label}
                  </Typography>
                }
                parentItem={parentItem}
              />
            )}
            {item?.children && (
              <TreeLeafWithChildrens
                index={index}
                item={item}
                allowRemove={allowRemove}
                onEdit={onEdit}
                onRemove={onRemove}
                onToggle={onToggle}
                onAddParentItem={onAddParentItem}
                onAddItem={onAddItem}
                KIcon={KIcon}
                pathEdit={pathEdit}
                setNewItems={setNewItems}
                parentItem={parentItem}
              />
            )}
          </TreeItem>
          <TreeAdd
            sx={{ pl: "38px", pr: "8px" }}
            onAddItem={onAddItem}
            onAddParentItem={!parentItem ? onAddParentItem : undefined}
            KIcon={KIcon}
            parentItem={parentItem}
            previousItemIndex={index}
          />
        </Box>
      ))}
    </Box>
  );
});
